<template>
  <div class="content-page">
    <div v-if="isLoading">
      <OtherLoading variant="dark" />
    </div>
    <div v-else>
      <div class="content-history">
        <RewardHistory :list="rewardsHistory" />
        <infinite-loading
          @infinite="infiniteHandler"
          ref="infiniteLoading"
        ></infinite-loading>
      </div>
    </div>
  </div>
</template>

<script>
import OtherLoading from "@/components/other/OtherLoading";
import Breadcrumb from "@/components/breadcrumb/Breadcrumb";
import RewardHistory from "@/components/reward/history/RewardHistory";
import InfiniteLoading from "vue-infinite-loading";
export default {
  components: {
    OtherLoading,
    Breadcrumb,
    RewardHistory,
    InfiniteLoading
  },
  data() {
    return {
      breadcrumb: [
        {
          text: "ประวัติการใช้งานย้อนหลัง",
          to: "/reward"
        }
      ],
      rewardsHistory: [],
      filter: {
        page: 1,
        take: 10
      },
      isLoading: true
    };
  },
  async created() {
    await this.getRewardHistory(false);
  },
  methods: {
    async getRewardHistory(scrollFlag, $state) {
      if (!scrollFlag) {
        this.filter.page = 1;
        this.rewardsHistory = [];
      }
      await this.$axios
        .post(`${process.env.VUE_APP_API}/api/v1/redeem/history`, this.filter)
        .then(
          async data => {
            this.isLoading = false;
            if (data.result == 1) {
              if (scrollFlag) {
                if (data.detail.length == 0) {
                  if ($state) $state.complete();
                } else {
                  this.rewardsHistory = [
                    ...this.rewardsHistory,
                    ...data.detail
                  ];
                  if ($state) $state.loaded();
                }
              } else {
                this.rewardsHistory = data.detail;
                if ($state) $state.loaded();
                this.onScrollToTop();
              }
            } else {
              if ($state) $state.complete();
            }
          },
          error => {
            const code = parseInt(error.response && error.response.status);
            if (code === 401) {
              console.log(error);
            }
          }
        );
    },
    onScrollToTop() {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
    },
    async infiniteHandler($state) {
      setTimeout(async () => {
        this.filter.page++;
        await this.getRewardHistory(true, $state);
      }, 500);
    }
  }
};
</script>

<style lang="scss" scoped>
.content-history {
  background-color: #fff;
  margin: 1rem;
  border-radius: 5px;
  padding: 10px 0;
}
::v-deep .infinite-status-prompt {
  display: none;
}
</style>
