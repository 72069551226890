<template>
  <div>
    <div v-if="list.physical">
      <div class="content-container-box" v-if="list.physical.length > 0">
        <div
          :class="[
            index != list.physical.length - 1 ? 'mb-2' : '',
            'content-privilege-group1 shadow'
          ]"
          v-for="(item, index) in list.physical"
          :key="index"
          @click="$router.push(`/rewardhistory/${item.redeem_item_id}`)"
        >
          <b-row class="line-top py-2">
            <b-col cols="5">
              <b-img-lazy
                v-if="item.image_path"
                v-bind="mainProps"
                :src="item.image_path"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
              <b-img-lazy
                v-else
                v-bind="mainProps"
                :src="require('@/assets/images/default-image.png')"
                class="lazy-image"
                alt="Image 1"
              ></b-img-lazy>
            </b-col>
            <b-col>
              <div class="title">{{ item.gift }}</div>
              <div class="description mt-1">
                {{ item.description ? item.description : "-" }}
              </div>
              <div class="point mt-1">
                <span class="fa-stack fa-1x icon-background">
                  <b-img
                    :src="$store.state.domain + $store.state.theme.point_logo"
                    class="img-point"
                    v-if="$store.state.theme.point_logo"
                  ></b-img>
                  <!-- {{ $store.state.domain + $store.state.theme.point_logo }} -->
                  <font-awesome-icon
                    v-else
                    icon="star"
                    class="point-icon-color"
                  />
                  <!-- :color="item.theme_icon_color" -->
                  <!-- <font-awesome-icon icon="star" class="point-icon-color" /> -->
                </span>
                {{ item.used_point }} คะแนน
              </div>
            </b-col>
          </b-row>
          <b-row class="px-2">
            <b-col cols="7" class="py-2">
              <div class="until-date">
                วันที่แลก: {{ dateFormat(item.redeem_date) }}
              </div>
            </b-col>
            <b-col cols="5" class="py-2 text-right">
              <div class="until-date">{{ item.status_name }}</div>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-else>
        <div class="notfound-data">ไม่มีประวัติแลกของรางวัล</div>
      </div>
    </div>
    <div v-else>
      <div class="notfound-data">ไม่มีประวัติแลกของรางวัล</div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: {
    list: {
      required: true,
      type: Object
    },
    totalCount: {
      required: true,
      type: Number
    }
  },
  data() {
    return {
      mainProps: {
        center: true,
        fluidGrow: false,
        blank: true,
        blankColor: "#bbb",
        width: 600,
        height: 600,
        class: ""
      }
    };
  },
  methods: {
    dateFormat(date) {
      return moment(date).locale("th").format("lll").replace("เวลา", "");
    }
  }
};
</script>

<style lang="scss" scoped>
.total-point {
  border-bottom: 1px solid gray;
}
.totalpoint-font {
  font-weight: 600;
  font-size: var(--text-xl);
}
.lazy-image {
  font-size: 1px;
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.content-reward-group {
  background-color: #f0f0f0;
}
.title {
  font-size: var(--text-lg);
  font-weight: 600;
  word-break: break-word;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.until-date {
  font-size: var(--text-md);
}
.status-false {
  opacity: 0.6;
}
.btn-redeem {
  background-color: transparent;
  color: #000;
  border: none;
}
.line-top {
  border-bottom: 1px dashed #aaaaaa;
  margin: 0 0;
  padding: 5px 0;
  // border-bottom: 1px dashed rgb(170, 170, 170);
}
.point {
  font-size: var(--text-md);
  font-weight: 600;
  // color: green;
}

.point-icon-color {
  color: #fff;
  padding-left: 5px;
}
.icon-background {
  background-color: #bb2e2e;
  height: 20px;
  width: 20px;
  border-radius: 50px;
}
</style>
